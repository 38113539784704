import {
  Link,
} from 'gatsby';
/* eslint-disable import/prefer-default-export */
import React, {
  useEffect, useState,
} from 'react';
import './src/styles/global.css';

export const wrapPageElement = ({ element }) => {
  const disableStr = `ga-disable-${process.env.GA_ID}`;
  const [hideBanner, setHideBanner] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem('cookie_banner')) {
      setHideBanner(false);
    }

    if (typeof window !== 'undefined' && localStorage.getItem(disableStr) === 'true') {
      window[disableStr] = true;
    }
  }, [disableStr]);

  const gaOptout = () => {
    localStorage.setItem(disableStr, 'true');
    window[disableStr] = true;
    localStorage.setItem('cookie_banner', 'true');
    setHideBanner(true);
  };

  const gaAccept = () => {
    document.cookie = 'gatsby-gdpr-google-analytics=true; path=/';
    localStorage.setItem('cookie_banner', 'true');
    setHideBanner(true);
  };

  return (
    <>
      {!hideBanner && (
        <div
          style={{
            background: 'black',
            opacity: 0.9,
            padding: '25px',
            position: 'fixed',
            bottom: '0',
            left: '0',
            right: '0',
            display: 'flex',
            alignItems: 'center',
            zIndex: '9999',
          }}
          className="lg:flex-row flex-col"
        >
          <div>
            <p
              style={{
                color: 'white',
                margin: '0 0 10px',
                textAlign: 'center',
              }}
              className="p-4"
            >
              Diese Webseite (nexxtra.de) nutzt Cookies zu unterschiedlichen Zwecken,
              unter anderem zur Analyse und für personalisierte Marketing-Mitteilungen.
              Durch Ihren Aufenthalt bei uns stimmen Sie der
              {' '}
              <Link
                to="/cookies"
                className="text-lime0"
              >
                Cookie Richtlinien
              </Link>
              {' '}
              und unseren Allgemeinen Geschäftsbedingungen zu. Wir freuen uns Sie hier begrüßen zu dürfen.
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '10px',
              maxWidth: '600px',
              width: '100%',
            }}
            className="lg:flex-row flex-col"
          >
            <button
              type="button"
              onClick={gaAccept}
              style={{
                flex: 1,
                padding: '10px',
                height: '40px',
                borderRadius: '5px',
                border: 'none',
                cursor: 'pointer',
                background: '#b3fd19',
                color: 'black',
                whiteSpace: 'nowrap',
              }}
            >
              Akzeptieren
            </button>
            <button
              type="button"
              onClick={gaOptout}
              style={{
                flex: 1,
                padding: '10px',
                height: '40px',
                borderRadius: '5px',
                border: 'none',
                cursor: 'pointer',
                background: 'darkred',
                color: 'white',
                whiteSpace: 'nowrap',
              }}
            >
              Nicht akzeptieren
            </button>
          </div>
        </div>

      )}
      {element}
    </>
  );
};
