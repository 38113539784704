exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-underconstruction-js": () => import("./../../../src/pages/underconstruction.js" /* webpackChunkName: "component---src-pages-underconstruction-js" */),
  "component---src-templates-career-details-js": () => import("./../../../src/templates/careerDetails.js" /* webpackChunkName: "component---src-templates-career-details-js" */),
  "component---src-templates-career-js": () => import("./../../../src/templates/career.js" /* webpackChunkName: "component---src-templates-career-js" */),
  "component---src-templates-insights-js": () => import("./../../../src/templates/insights.js" /* webpackChunkName: "component---src-templates-insights-js" */),
  "component---src-templates-news-articles-js": () => import("./../../../src/templates/newsArticles.js" /* webpackChunkName: "component---src-templates-news-articles-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

